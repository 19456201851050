import { PageProps } from 'gatsby';
import { UserData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '../../../components/View';
import Form from '../../../containers/Admin/Form';
import params from '../../../params/user/index.json';
import TemplateAdmin from '../../../templates/admin';
import requireUser from '../../../utils/requireUser';

const PageAdminUsersCreate: FC<PageProps> = () => (
  <TemplateAdmin>
    <CMSView>
      <Form model={new UserData({ params })} name="user" type="create" />
    </CMSView>
  </TemplateAdmin>
);

export default requireUser(PageAdminUsersCreate, 'admin');
